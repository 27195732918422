import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import usePreventReload from '../../../hooks/usePreventReload';
import "moment-timezone";
import { CREW_STANDBY_BR, CREW_STANDBY_SSC } from '../../../utils/Constants';
import React, { useState } from "react";
import { Prompt } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import GenericSnackbar from "../../GenericSnackbar/GenericSnackbar";
import "./CrewStandbyConfig.css";
// import CrewStandbyMaintainer from "./CrewStandbyMaintainer/CrewStandbyMaintainer";
// import CrewStandbyMaintainerBrasil from "./CrewStandbyMaintainerBrasil/CrewStandbyMaintainerBrasil";

function CrewStandbyConfig(props) {
  const [crewStandby] = useState({
    fltSummaryShift: { data: [] },
    fltSummaryShiftBra: { data: [] },
  });
  console.log(crewStandby);

  const [isDirty, setDirty, setClean] = usePreventReload();
  const [isLoading, setIsLoading] = useState(false);
  const [snackbar, setSnackbar] = useState("");

  const [state, setState] = React.useState(props.configurationCheckbox && props.configurationCheckbox.crewStandbyConfig ? props.configurationCheckbox.crewStandbyConfig : {
    FiltersBR: CREW_STANDBY_BR,
    FiltersSSC: CREW_STANDBY_SSC,
    checked: props.userCCO,
    lastModDate: ''
  });

  const handleChange = (event) => {
    setState({ ...state, checked: event.target.value });
    setDirty(true);
    console.log("Checked value updated:", event.target.value);
  };

  const handleFiltersChange = (event, newValue) => {
    if (state.checked === "SSC") {
      setState({ ...state, checked: "SSC", FiltersSSC: newValue });
    } else {
      setState({ ...state, checked: "BR", FiltersBR: newValue });
    }
    console.log("Filtros seleccionados:", newValue);
  };

  const saveChanges = async () => {
    setIsLoading(true);
    const modDate = new Date();
    state.lastModDate = modDate.toUTCString();

      props.updateConfigurationData('crewStandbyConfig', state).then(() => {
        setSnackbar({ msg: "Succesfully saved changes!", date: new Date(), severity: "success" });
        setState({ ...state, lastModDate: modDate, });
        setClean();
      }, error => {
        console.error(error);
        setSnackbar({ msg: "Error saving changes. Try again later", date: new Date(), severity: "error" });
    });

    setIsLoading(false);
  };

  return (
    <div className="ground-support-config">
      <div className="w-title">
        <span>Crew Standby</span>
      </div>
      <Tabs className="w-tab">
        <TabList>
          <Tab>Filter</Tab>
          {/* <Tab>Group SSC</Tab>
          <Tab>Group BR</Tab> */}
        </TabList>

        <div className="contenedor">
          <TabPanel>
            <div className="desc">
              Select what you want to see on CrewStandby.
            </div>

            <div className="cf-filters block">
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                  value={state.checked} // Bind the state directly here
                  onChange={handleChange} // Apply onChange to the RadioGroup
                >
                  <FormControlLabel
                    value="SSC"
                    control={<Radio />}
                    label="SSC"
                  />
                  <FormControlLabel
                    value="BR"
                    control={<Radio />}
                    label="BR"
                  />
                </RadioGroup>
              </FormControl>
            </div>

            <div className="cf-filters">
              <div className="cf-autocomplete">
                <div className="ac-label">Select Filial</div>
                {isLoading ? (
                  <p>Cargando datos de la flota...</p>
                ) : (
                  <Autocomplete
                    multiple
                    freeSolo
                    limitTags={4}
                    id="multiple-limit-tags-fleets"
                    options={state.checked === "SSC" ? CREW_STANDBY_SSC : CREW_STANDBY_BR}
                    value={state.checked === "SSC" ? state.FiltersSSC : state.FiltersBR} //aqui van los valores deentro del slected final
                    onChange={handleFiltersChange}
                    classes={{ tag: "ac-tag" }}
                    disableCloseOnSelect={true}
                    renderOption={(option, { selected }) => (
                      <div className="option">
                        <Checkbox
                          color="primary"
                          classes={{ root: "ac-checkbox" }}
                          style={{ marginRight: "1vh" }}
                          checked={selected}
                        />
                        {option}
                      </div>
                    )}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" label="Fleet" />
                    )}
                  />
                  
                )}
              </div>
            </div>

            <div className="cf-buttons">
              <div className="last-mod"></div>
              <Button
                variant="contained"
                classes={{ root: "vw-button" }}
                onClick={saveChanges}
                disableElevation
              >
                Apply changes to video wall
              </Button>
            </div>
            {snackbar ? (
              <GenericSnackbar
                key={snackbar.date}
                severity={snackbar.severity}
                message={snackbar.msg}
              />
            ) : null}
          </TabPanel>

          {/* <TabPanel>
            <div className="w-filters">
              <CrewStandbyMaintainer
                crewStandby={crewStandby.fltSummaryShift.data}
                lastState={"SSC"}
              ></CrewStandbyMaintainer>
            </div>
          </TabPanel>

          <TabPanel>
            <div className="w-filters">
              <CrewStandbyMaintainerBrasil
                crewStandby={crewStandby.fltSummaryShiftBra.data}
                lastState={"SSC"}
              ></CrewStandbyMaintainerBrasil>
            </div>
          </TabPanel> */}
        </div>
      </Tabs>

      {snackbar ? (
        <GenericSnackbar
          key={snackbar.date}
          severity={snackbar.severity}
          message={snackbar.msg}
        />
      ) : null}
      <Prompt
        when={isDirty}
        message="You have unsaved changes. Are you sure you want to leave?"
      />
    </div>
  );
}

export default CrewStandbyConfig;
